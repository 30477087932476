import StarFilledIcon from "@/public/icons/star-filled.svg"
import GoogleLogo from "public/images/google-logo.svg"

import { FC } from "react"

const LeaveAReviewCTA: FC = () => {
  return (
    <div className="leave-a-review-container group">
      <div className="flex group-hover:py-[46px] lg:group-hover:py-4 pb-[30px] lg:pb-0 transition-all duration-300 ease-in items-center gap-[15px] lg:gap-[30px]">
        <GoogleLogo className="w-9 lg:w-12" />
        <div className="flex text-right lg:text-left  items-end lg:items-center flex-col lg:flex-row gap-[15px]  lg:gap-[30px]">
          <div>
            <h5 className="font-bold lg:text-lg">We Love our community</h5>
            <p>
              522 Google reviews | <b>4.5</b>
            </p>
          </div>
          <div className="hidden lg:flex">
            {new Array(5).fill(null).map((_, i) => (
              <StarFilledIcon
                className="w-6 h-6 lg:w-9 lg:h-9 text-[#ffcc00]"
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
      <a
        aria-label="Leave a review"
        className="leave-a-review-button animate-down-and-right group-hover:inline-block"
        href="https://www.google.com/search?q=Body+Factory+Bali&rlz=1C5CHFA_enNL929NL929&oq=Body+Factory+Bali&aqs=chrome..69i57j69i59j69i60l3.243j0j9&sourceid=chrome&ie=UTF-8#lrd=0x2dd24756803b6cdd:0x2c12b148b65fb,3,,,,"
      >
        Leave Us A Review
      </a>
    </div>
  )
}

export default LeaveAReviewCTA
