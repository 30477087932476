import Link from "next/link"
import { FC, ReactNode } from "react"

import HeroCarousel from "@/components/HeroCarousel"
import LeaveAReviewCTA from "@/components/LeaveAReviewCTA"

const HeroSection: FC<{ images?: string[]; text?: ReactNode }> = ({
  images,
  text,
}) => {
  return (
    <section className="relative mb-[72px] lg:mb-[134px] min-h-[calc(100vh-85px)]">
      <HeroCarousel images={images} />
      <div className="absolute z-[10]  lg:bottom-[150px] top-[30%] lg:h-auto flex items-center left-0 right-0">
        <div className="mx-auto w-[90%]   max-w-screen-2xl flex flex-col justify-start lg:flex-row gap-[30px]  items-center lg:items-center lg:justify-between">
          <div>
            {text ? (
              text
            ) : (
              <h1 className="text-xl text-shadow text-center lg:text-left font-bold tracking-tight text-white md:text-3xl lg:text-[2.75rem] lg:leading-[50px] font-display">
                <span>WELCOME TO</span>
                <br />
                <span className="text-brand text-2xl md:text-4xl lg:text-[3rem]">
                  BODY FACTORY BALI
                </span>
              </h1>
            )}
            <div className="flex justify-center lg:justify-start">
              <Link
                aria-label="Get Started with a Gym Membership"
                className="bg-brand hover:bg-white  focus:bg-white hover:text-brand focus:text-brand transition-colors duration-150 ease-out rounded-[10px] font-bold text-xl -skew-x-[21deg] inline-block md:pl-[107px] pl-[53px] pr-[50px] pt-[10px] pb-[8px]  md:pr-[101px] md:pt-[20px] md:pb-[16px] mt-4"
                href="/gym-memberships"
              >
                <div className="skew-x-[21deg] flex  uppercase items-center gap-[14px]">
                  GET STARTED
                </div>
              </Link>
            </div>
          </div>
          <div className="hidden lg:block">
            <LeaveAReviewCTA />
          </div>
        </div>
      </div>
      <div className="absolute z-50 scale-75 bottom-8 left-1/2 right-1/2">
        <div className="mouse"></div>
      </div>
    </section>
  )
}

export default HeroSection
