import type { NextPage } from "next"
import dynamic from "next/dynamic"

import Seo from "@/components/Seo"

import HeroSection from "@/sections/HeroSection"

const HomePageBottom = dynamic(() => import("@/sections/HomePageBottom"))
const InclusiveApproachSection = dynamic(
  () => import("@/sections/InclusiveApproachSection"),
)

const Home: NextPage = () => {
  return (
    <main className="w-full">
      <Seo title="Body Factory Bali gym and fitness" />
      <HeroSection />
      <InclusiveApproachSection />
      <HomePageBottom />
    </main>
  )
}

export default Home
