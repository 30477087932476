import Head from "next/head"
import { FC } from "react"

const Seo: FC<{ title: string; description?: string }> = ({
  title,
  description = "Transform your lifestyle with the ultimate wellness destination in Canggu.",
}) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Head>
  )
}

export default Seo
